import { createClient, OperationResult } from 'urql';
import { NFT } from '@/configs/nft';
import { prod } from '@/common/project-environment';

const API_URL_DEV = 'https://api.thegraph.com/subgraphs/name/neco-fun/neco-fun-nft';
const API_URL = 'https://api.thegraph.com/subgraphs/name/neco-fun/neco-fun-nft-prod';

const client = createClient({
  url: prod() ? API_URL : API_URL_DEV,
});

export default function getNFTs()
    : Promise<Array<NFT>> {
  const query = `
  query {
    nfts {
      id
      nftId
      nftType1
      nftType2
      metadataUrl
      createTime
      owner {
        id
        user {
          id
          address
        }
        quantity
      }
    }
  }
`;
  return client.query(query).toPromise()
    .then((
      res:OperationResult<{ nfts: Array<NFT> }, Record<string, any>>,
    ) => res?.data?.nfts || []);
}
