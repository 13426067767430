export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT Library"])},
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have NFT"])}
      },
      "zh": {
        "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT 图书馆"])},
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂时没有NFT"])}
      }
    }
  })
}
